import React from "react";
import FormWrapper from "../../../../../components/form_wrapper_component/FormWrapperComponent";
import PrimaryButton from "../../../../../components/primary_button_component/PrimaryButtonComponent";
import SecondaryButton from "../../../../../components/secondary_button_component/SecondaryButtonComponent";
import SizedBox from "../../../../../components/sized_box_component/SizedBoxComponent";
import Header from "../../../../../components/text_components/header_component/HeaderComponent";
import TextInput from "../../../../../components/text_input_component/TextInputComponent";
import Text from "../../../../../components/text_components/text_component/TextComponent";

import concentraidIcon from "../../../../../../assets/images/concentraid_icon.svg";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../../../stores/UserStore";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { isEmailValid } from "../../../../../../helpers/ValidationHelper";
import Switch from "react-switch";
import "./SignUpFormComponent.scss";
import { toast } from "react-toastify";

interface SignUpFormProps {
  userStore?: UserStore;
}

const SignUpForm = ({ userStore }: SignUpFormProps) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailHasError, setEmailHasError] = useState(false);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [readAGB, setReadAGB] = useState(false);

  const signUp = async () => {
    if (readAGB) {
      setIsLoading(true);
      await userStore?.signUp(email, password);
      setIsLoading(false);
    } else {
      toast.error("Please accept the AGBs first");
    }
  };

  const handleEnter = (event: any) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      signUp();
    }
  };

  return (
    <FormWrapper className="sign-in-form default-padding">
      <>
        <img className="logo" src={concentraidIcon} alt="concentraid_icon" />
        <div className="input-content full-width">
          <Header>Start with 3 free sessions every week!</Header> 
          <Text>
            Register completely free. No Payment details required.
          </Text>
          <SizedBox height={20} />
          <Header className="register-text">Register</Header>
          <SizedBox height={10} />
          <SizedBox height={30} />
          <TextInput
            hasError={emailHasError}
            errorText="Invalid mail address"
            placeholder="Email*"
            onChange={(input: string) => {
              setEmail(input); 
              if (isEmailValid(input)) {
                setEmailHasError(false);
              } else {
                setEmailHasError(true);
              }
            }}
            onKeyPress={handleEnter}
          />
          <SizedBox height={15} />
          <TextInput
            hasError={passwordHasError}
            errorText="Password must contain at least 6 characters." 
            placeholder="Password*"
            type="password"
            onChange={(input: string) => {
              setPassword(input);
              if (input.length >= 6) {
                setPasswordHasError(false);
              } else {
                setPasswordHasError(true);
              }
            }}
            onKeyPress={handleEnter}
          />
        </div>
        <SizedBox height={10} />
        <div className="switch-style-agb">
          <Switch
            height={27}
            width={55}
            onChange={() => {
              setReadAGB(!readAGB);
            }}
            checked={readAGB}
          />
          <SizedBox width={10} />
          <Text>I have read the</Text>
          <a className="terms-link" href="https://myconcentraid.com/terms" target="_blank">terms and conditions</a>
        </div>
        <SizedBox height={20} />
        <div className="row full-width">
          <SecondaryButton
            className="sign-in-button"
            label="Go to login"
            onClick={() => {
              history.push("/sign-in");
            }}
          />
          <SizedBox width={30} />
          <PrimaryButton
            loading={isLoading}
            disabled={email === "" || password === ""}
            className="sign-in-button"
            label="Register"
            onClick={signUp}
          />
        </div>
      </>
    </FormWrapper>
  );
};

export default inject("userStore")(observer(SignUpForm));
