import Routing from "../../routing/Routing";
import { Provider as MobxProvider } from "mobx-react";
import ModalStore from "../../stores/ModalStore";
import UserStore from "../../stores/UserStore";
import { ToastContainer } from "react-toastify";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import AppointmentStore from "../../stores/AppointmentStore";
import moment from "moment-timezone";
import "moment/locale/en-gb";
import SocketStore from "../../stores/SocketStore";
import ReactGA from "react-ga";
//@ts-ignore
import Heap from "react-heap";
import "./App.scss";
import TimerStore from "../../stores/TimerStore";
import { useEffect } from "react";

moment.tz.setDefault();
moment.locale("en-gb");

const checkCookie = () => {
  ReactGA.initialize("UA-205052532-1");
  ReactGA.pageview(window.location.pathname);
  return <Heap appId="260982392" />;
};

const userStore = new UserStore();
const modalStore = new ModalStore();
const appointmentStore = new AppointmentStore();
const socketStore = new SocketStore();
const timerStore = new TimerStore();

socketStore.setStore("appointmentStore", appointmentStore);
socketStore.setStore("userStore", userStore);
socketStore.setStore("timerStore", timerStore);
socketStore.setStore("modalStore", modalStore);
socketStore.connect();

timerStore.setStore("appointmentStore", appointmentStore);

appointmentStore.initStore([userStore]);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0db358",
    },
  },
});

const App: React.FC = () => {
  useEffect(() => {
    if (userStore.currentUser && userStore.currentUser.id) {
      socketStore.connectUserChannels();
    }
  }, [userStore.currentUser]);

  return (
    <div className="app-container">
      <ThemeProvider theme={theme}>
        <MobxProvider
          userStore={userStore}
          modalStore={modalStore}
          appointmentStore={appointmentStore}
          timerStore={timerStore}
          socketStore={socketStore}
        >
          {checkCookie()}
          <Routing />
          <ToastContainer />
        </MobxProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
