import React, { useState } from "react";

import Text from "../../components/text_components/text_component/TextComponent";
import Card from "../../components/card_component/CardComponent";
import SizedBox from "../../components/sized_box_component/SizedBoxComponent";
import { ClipLoader } from "react-spinners";
import { Appointment } from "../../../models/Appointment";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import AppointmentStore from "../../../stores/AppointmentStore";
import moment from "moment-timezone";
import "./SidebarComponent.scss";
import ModalStore from "../../../stores/ModalStore";
import TimerStore from "../../../stores/TimerStore";
import PopupReferalComponent from "../popup_components/PopupReferal/PopupReferalComponent";

interface SidebarProps {
  appointmentStore?: AppointmentStore;
  modalStore?: ModalStore;
  width: Number;
  timerStore?: TimerStore;
}

const SidebarComponent = ({
  appointmentStore,
  modalStore,
  width,
  timerStore,
}: SidebarProps) => {
  const appointmentList = appointmentStore!.appointmentList;
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // Filter appointments by timespan
    let interval = setInterval(() => {
      let startDate = moment();
      startDate.subtract(55, "minutes");

      const updatedAppointmentsList = appointmentStore!.appointmentList.filter(
        (appointmentItem: Appointment) =>
          moment(appointmentItem.startdate).valueOf() > startDate.valueOf()
      );

      appointmentStore?.setAppointmentList(updatedAppointmentsList);
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const createSessionCard = () => {
    if (appointmentList && appointmentList.length === 0) {
      return <></>;
    }
    if (appointmentList) {
      return appointmentList.map((appointment: Appointment, key: number) => {
        if (appointmentList.length === key + 1) {
          return <div key={key}></div>;
        }
        return (
          <div key={key} className="row card-container2">
            <SizedBox height={20} />
            <Card appointment={appointmentList[key + 1]} isFirst={false} />
          </div>
        );
      });
    }
    return <></>;
  };

  const createFirstSessionCard = () => {
    if (appointmentList && appointmentList.length > 0) {
      return (
        <div>
          {appointmentStore?.useMobile ? (
            <Text className="bold small-text">My Sessions</Text>
          ) : (
            <Text className="bold small-text">Next Session</Text>
          )}
          <SizedBox height={10} />
          <div className="row">
            <Card appointment={appointmentList[0]} isFirst />
            <SizedBox height={20} />
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderSessions = () => {
    if (appointmentList && appointmentList.length === 0) {
      return (
        <div>
          {appointmentStore?.useMobile ? (
            <SizedBox height={10} />
          ) : (
            <SizedBox height={10} />
          )}
          <Text className="bold small-text">My sessions</Text>
          <Text className="small-text mt-15">
            You don't have any upcoming sessions
          </Text>
          <SizedBox height={20} />
          <Text className="description-box">
            When are you planning to work? Just click on a timeslot to reserve
            the time for your session.
          </Text>
          {createSessionCard()}
        </div>
      );
    }

    return (
      <div className="card-row">
        {appointmentStore?.useMobile ? (
          <SizedBox height={30} />
        ) : (
          <SizedBox height={40} />
        )}
        {appointmentList &&
          appointmentList.length > 1 &&
          !appointmentStore?.useMobile && (
            <Text className="bold small-text">My Sessions</Text>
          )}
        {createSessionCard()}
      </div>
    );
  };

  return (
    <div className="sidebar">
      <div className="session-column">
        {isLoading ? <ClipLoader /> : createFirstSessionCard()}
        {isLoading ? <></> : renderSessions()}
      </div>
      {Number(width) > 700 && (
        <div className="info-text-container">
           {/* Refer to a friend - temporarily excluded
          <div
            onClick={() => {
               // window.open(
               //  "mailto:?&cc=luca@boehlerbrothers.com&subject=Account löschen&body=" +
               //  emailMessage
               // );
              modalStore?.toggleReferalPopUp();
            }}
          >
            <Text className="guidelines-link">Refer to a friend</Text>
          </div>*/}
          <div
            onClick={() => {
              modalStore?.toggleAddFavouriteUserPopup();
            }}
          >
            <Text className="guidelines-link">Favourite a co-worker</Text>
          </div>
          <div
            onClick={() => {
              modalStore?.toggleRulesPopUp();
            }}
          >
            <Text className="guidelines-link">Guidelines</Text>
          </div>
          <SizedBox height={10} />
          <div className="row">
            <div
              onClick={() => {
                window.location.replace(
                  "https://www.myconcentraid.com/imprint"
                );
              }}
            >
              <Text className="info-text">Imprint</Text>
            </div>
            <SizedBox width={20} />
            <div
              onClick={() => {
                window.location.replace(
                  "https://www.myconcentraid.com/privacy"
                );
              }}
            >
              <Text className="info-text">Data Privacy Policy</Text>
            </div>
          </div>
          <SizedBox height={10} />
          <Text className="small-text">©2024 ConcentrAid</Text>
          <PopupReferalComponent />
        </div>
      )}
    </div>
  );
};

export default inject(
  "modalStore",
  "appointmentStore",
  "timerStore"
)(observer(SidebarComponent));
