import React, { useEffect, useState } from "react";
import Countdown from "../../../../components/countdown_component/CountdownComponent";
import PrimaryButton from "../../../../components/primary_button_component/PrimaryButtonComponent";
import SizedBox from "../../../../components/sized_box_component/SizedBoxComponent";
import Header from "../../../../components/text_components/header_component/HeaderComponent";
import Text from "../../../../components/text_components/text_component/TextComponent";
import { parseJwt } from "../../../../../helpers/jwt_decode_helper";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Appointment } from "../../../../../models/Appointment";
import ModalStore from "../../../../../stores/ModalStore";
import ReactGA from "react-ga";
import AppointmentStore from "../../../../../stores/AppointmentStore";
import YouTube from "@u-wave/react-youtube";
import "./SessionOptionComponent.scss";
import FinishedSession from "../../../../components/finished_session/FinishedSession";
import UserStore from "../../../../../stores/UserStore";
import { isMobile } from "react-device-detect";
import moment from "moment";
import TimerStore from "../../../../../stores/TimerStore";

interface SessionOptionComponentProps {
  appointment?: Appointment;
  modalStore?: ModalStore;
  jwt: string;
  appointmentStore?: AppointmentStore;
  userStore?: UserStore;
  timerStore?: TimerStore;
}

const SessionOptionComponent = ({
  jwt,
  modalStore,
  appointmentStore,
  appointment,
  userStore,
  timerStore,
}: SessionOptionComponentProps) => {
  const [choosenOption, setChoosenOption] = useState<
    "VIDEO" | "TIMER" | "WHITENOISE" | null
  >();
  const [expireTime, setExpireTime] = useState<number>();
  const [change, setChange] = useState(false);
  const [finished, setFinished] = useState(false);
  const [isMatch, setMatch] = useState(false);

  const decodedToken: any = parseJwt(jwt);
  const history = useHistory();

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    updateAppointment();
  }, [isMatch]);

  const updateAppointment = () => {
    if (appointment) {
      const currentAppointment = { ...appointment };
      switch (userStore?.currentUser?.id) {
        case appointment?.firstUser?.id:
          currentAppointment.firstUserJoined = true;
          break;
        case appointment?.secondUser?.id:
          currentAppointment.secondUserJoined = true;
          break;
      }
      appointmentStore?.updateCurrentAppointment(currentAppointment);
    }
  };

  const initialize = () => {
    document.title = "ConcentrAid";
    let currentUser = userStore?.currentUser;

    if (appointment?.secondUser) {
      setMatch(true);
    }

    if (appointment?.firstUser) {
      if (
        appointment?.firstUser.id === currentUser?.id &&
        !appointment?.firstUserJoined
      ) {
        checkIfToLate();
      }
    }
    if (appointment?.secondUser) {
      if (
        appointment?.secondUser.id === currentUser?.id &&
        !appointment?.secondUserJoined
      ) {
        checkIfToLate();
      }
    }

    // Check if starttime is valid
    if (decodedToken?.nbf * 1000 > new Date().getTime()) {
      modalStore?.toggleAfterSessionPopUp();
      ReactGA.modalview("/after-session-popup");
      return history.push("/");
    }

    const calcAvailableTime = decodedToken?.exp * 1000 - new Date().getTime();

    setTimeout(
      () => {
        setFinished(true);
      },
      calcAvailableTime > 0 ? calcAvailableTime : 1
    );

    // Calculate available time and set internval
    setExpireTime(decodedToken?.exp * 1000);
  };

  const checkIfToLate = () => {
    if (appointmentStore?.isToLate) {
      modalStore?.toggleWarningPopUp();
      appointmentStore && (appointmentStore.isToLate = false);
    }
  };

  // on mobile the video doesnt starts automatically so it starts the video if its ready
  const startVideo = (event: any) => {
    event.target.playVideo();
  };

  if (finished) {
    return <FinishedSession />;
  }

  if (isMobile && change) {
    return (
      <div className="youtube-container-mobile">
        {choosenOption === "VIDEO" && (
          <div className="transparent-box">
            <YouTube
              video="QbzmI5ORDhw"
              className="youttube-container"
              width="100%"
              height="100%"
              controls={false}
              allowFullscreen={false}
              disableKeyboard={true}
              modestBranding={false}
              showRelatedVideos={false}
              showInfo={false}
              startSeconds={25}
              autoplay={true}
              muted={true}
              onReady={(event) => {
                startVideo(event);
              }}
            />
            <Countdown className="countdown-component" startTime={expireTime} />
          </div>
        )}
        {choosenOption === "TIMER" && (
          <div className="youtube-container center">
            <Countdown startTime={expireTime} />
          </div>
        )}
        {choosenOption === "WHITENOISE" && (
          <div className="transparent-box">
            <YouTube
              video="nMfPqeZjc2c"
              className="youttube-container"
              width="100%"
              height="100%"
              controls={false}
              allowFullscreen={false}
              disableKeyboard={true}
              modestBranding={false}
              showRelatedVideos={false}
              showInfo={false}
              startSeconds={7}
              autoplay={true}
              muted={false}
              onReady={(event) => {
                startVideo(event);
              }}
            />
            <Countdown className="countdown-component" startTime={expireTime} />
          </div>
        )}
      </div>
    );
  }

  if (change) {
    return (
      <div className="youtube-container">
        {choosenOption === "VIDEO" && (
          <div className="transparent-box" onClick={() => {}}>
            <YouTube
              video="QbzmI5ORDhw"
              className="youttube-container"
              width="100%"
              height="100%"
              controls={false}
              allowFullscreen={false}
              disableKeyboard={true}
              modestBranding={false}
              showRelatedVideos={false}
              showInfo={false}
              startSeconds={25}
              autoplay={true}
              muted={true}
              onReady={(event) => {
                startVideo(event);
              }}
            />
            <Countdown className="countdown-component" startTime={expireTime} />
          </div>
        )}
        {choosenOption === "TIMER" && (
          <div className="youtube-container center">
            <Countdown startTime={expireTime} />
          </div>
        )}
        {choosenOption === "WHITENOISE" && (
          <div className="transparent-box" onClick={() => {}}>
            <YouTube
              video="nMfPqeZjc2c"
              className="youttube-container"
              width="100%"
              height="100%"
              controls={false}
              allowFullscreen={false}
              disableKeyboard={true}
              modestBranding={false}
              showRelatedVideos={false}
              showInfo={false}
              startSeconds={7}
              autoplay={true}
              muted={false}
              onReady={(event) => {
                startVideo(event);
              }}
            />
            <Countdown className="countdown-component" startTime={expireTime} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="option-container">
      {isMatch ? (
        <div className="text-container">
          <Header className="option-header">
            Unfortunately your partner did not show up.
          </Header>
          <SizedBox height={10} />
          <Text className="option-header">
            How would you like to work instead
          </Text>
          <SizedBox height={100} />
        </div>
      ) : (
        <div className="text-container">
          <Header className="option-header">
            Sorry we can only match you with a partner if you are on time.
          </Header>
          <SizedBox height={10} />
          <Text className="option-header">
            Check out our alternative productivity supporters with timer below.
          </Text>
          <SizedBox height={100} />
        </div>
      )}
      <div className="button-row">
        <PrimaryButton
          textStyle="button-text"
          className="option-button"
          label="with Anna"
          onClick={() => {
            setChoosenOption("VIDEO");
            setChange(true);
          }}
        />
        <PrimaryButton
          textStyle="button-text"
          className="option-button"
          label="with Timer"
          onClick={() => {
            setChoosenOption("TIMER");
            setChange(true);
          }}
        />
        <PrimaryButton
          textStyle="button-text"
          className="option-button"
          label="with White Noise"
          onClick={() => {
            setChoosenOption("WHITENOISE");
            setChange(true);
          }}
        />
      </div>
    </div>
  );
};

export default inject(
  "appointmentStore",
  "modalStore",
  "userStore",
  "timerStore"
)(observer(SessionOptionComponent));
